:root {
  --ion-color-primary: #009fdf;
  --ion-color-primary-rgb: 0,159,223;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #008cc4;
  --ion-color-primary-tint: #1aa9e2;

  --ion-color-secondary: #8dd8f8;
  --ion-color-secondary-rgb: 141,216,248;
  --ion-color-secondary-contrast: #383838;
  --ion-color-secondary-contrast-rgb: 33,21,81;
  --ion-color-secondary-shade: #7cbeda;
  --ion-color-secondary-tint: #98dcf9;

  --ion-color-tertiary: #211451;
  --ion-color-tertiary-rgb: 33,20,81;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #1d1247;
  --ion-color-tertiary-tint: #372c62;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #383838;
  --ion-color-dark-rgb: 34,36,40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #383838;
  --ion-color-light-contrast-rgb: 34,36,40;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0,0,0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

body {
  background-color: #009fdf;
}


ion-split-pane {
  --border: 0px solid var(--ion-color-primary);
}

.split-pane-visible >.split-pane-side {
  min-width: 60px; /* Default: 270px */
  max-width: 60px; /* Same as when menu opens in mobile mode */
}

ion-icon {
  pointer-events :none;
}

ion-app {
  margin-bottom: 0;
  transition: margin-bottom 320ms;
}

.sc-ion-searchbar-ios {
  border-radius: 10px !important;
  -webkit-box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1) !important;
}

.sc-ion-searchbar-md {
  border-radius: 10px !important;
  -webkit-box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1) !important;
}

.modal-create-view {
  /*--height: calc(100%);*/
  /*--border-radius: 0px 0px 0px 0px;*/
  /*align-items: flex-end;*/
  /*justify-items: flex-end;*/
  --height: calc(100% - 100px);
  --border-radius: 2px 2px 0px 0px;
  align-items: flex-end;
}

.modal-edit-view {
  /*--height: calc(100%);*/
  /*--border-radius: 0px 0px 0px 0px;*/
  /*align-items: flex-end;*/
  /*justify-items: flex-end;*/
  --height: calc(100% - 100px);
  --border-radius: 2px 2px 0px 0px;
  align-items: flex-end;
}

.modal-popup-view {
  /*--height: calc(100%);*/
  /*--border-radius: 0px 0px 0px 0px;*/
  /*align-items: flex-end;*/
  /*justify-items: flex-end;*/
  --height: calc(100% - 200px);
  --border-radius: 2px 2px 0px 0px;
  align-items: flex-end;
}

.select-alert {
  --min-width: 70%
}

.border-radius-0 {
  --border-radius: 0px;
}

.input-field {
  border: 1px solid var(--ion-color-light);
  border-radius: 0px;
  margin-top: 12px;
  --padding-start: 8px !important;
}

.default-section-padding {
  padding: 5px;
}

.default-element-border {
  border-radius: 2px;
}